import { Capacitor } from '@capacitor/core'

export default function (rollId: string) {
  let protocol
  if (Capacitor.isNativePlatform()) {
    // prevent protocol to be capacitor://
    protocol = 'https:'
  } else {
    protocol = location.protocol
  }

  return `${protocol}//${location.host}/start/?token=${rollId}`
}
